<template>
	<el-scrollbar class="uu-box">
		<div class="uu-order">
			<!-- <div class="uu-message">
				<div class="message-notice">
					<i class="el-icon-warning"></i>
					<div class="message-notice-main">
						<p>{{$t('seller.order.consignTips1')}} <a href="#">{{$t('seller.actions.questionnaire')}}</a>
						</p>
						<p>{{$t('seller.order.consignTips2')}} <a href="#">{{$t('seller.actions.survey')}}</a>。</p>
						<p>{{$t('seller.order.consignTips3')}}</p>
					</div>
				</div>
			</div> -->
			<el-tabs v-model="activeTabs" @tab-click="handleTabsClick">
				<el-tab-pane v-for="(item,index) in navList" :key="index" :label="item.label" :name="item.value"></el-tab-pane>
			</el-tabs>
			<div class="uu-order-main">
				<!-- <div class="uu-order-head">
					<ul>
						<li>
							<span>{{$t('seller.order.consignText1')+':'}}</span>
							<el-radio-group v-model="screenForm.type" size="small" @change="handleSearchList">
								<el-radio-button v-for="(item,index) in typeOption" :key="index" :label="item.value">{{item.label}}</el-radio-button>
							</el-radio-group>
						</li>
						<li v-if="activeTabs == 'accepted'">
							<span>{{$t('seller.order.consignText2')+':'}}</span>
							<div class="li-black">
								<el-button size="small" :class="{active:screenForm.shipment_alert==24}" @click="handleWarn(24)">{{'24'+$t('seller.order.consignText3')}}</el-button>
								<el-button size="small" :class="{active:screenForm.shipment_alert==48}" @click="handleWarn(48)">{{'48'+$t('seller.order.consignText3')}}</el-button>
							</div>
						</li>
						<li>
							<el-checkbox v-model="containRemark" v-if="activeTabs == 'accepted'" true-label="1" false-label="" size="small" @change="handleSearchList">{{$t('seller.checkbox.buyerLeave')}}</el-checkbox>
							<el-checkbox :label="$t('seller.checkbox.rookieBill')" size="small"></el-checkbox>
						</li>
					</ul>
				</div> -->
				<div class="uu-screen">
					<el-form v-model="screenForm" :inline="true">
						<el-form-item class="form-item-time">
							<div class="item">
								<div class="item-label">{{$t('seller.screen.payTime')}}</div>
								<el-date-picker v-model="makeTime" type="datetimerange" :range-separator="$t('seller.unit.to')"
									:start-placeholder="$t('seller.placeholder.startDate')" :end-placeholder="$t('seller.placeholder.endDate')" prefix-icon=" "
									value-format=timestamp :picker-options="startPickerOptions">
								</el-date-picker>
							</div>
						</el-form-item>
						<el-form-item>
							<el-input v-model="screenForm.consignee" class="screen-input" clearable>
								<template slot="prefix">{{$t('seller.screen.recipientName')}}</template>
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-input v-model="screenForm.phone_mob" class="screen-input" clearable>
								<template slot="prefix">{{$t('seller.screen.recipientMobile')}}</template>
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-input v-model="screenForm.order_sn" clearable>
								<template slot="prefix">{{$t('seller.screen.orderSn')}}</template>
							</el-input>
						</el-form-item>
						<el-form-item>
							<div class="item">
								<div class="item-label">{{$t('seller.screen.address')}}</div>
								<el-cascader v-model="screenRegion" clearable :options="addressList"
									:props="addressProps">
								</el-cascader>
							</div>
						</el-form-item>
						<el-form-item>
							<el-select v-model="screenForm.source" clearable>
								<template slot="prefix">{{$t('seller.screen.orderSource')}}</template>
								<el-option v-for="(item,index) in sourceOption" :key="index" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-input v-model="screenForm.goods_ids" clearable>
								<template slot="prefix">{{$t('seller.screen.babyId')}}</template>
							</el-input>
						</el-form-item>
						<template v-if="activeTabs == 'shipped'">
							<el-form-item>
								<el-select v-model="screenForm.delivery_type" clearable>
									<template slot="prefix">{{$t('seller.screen.shippingType')}}</template>
									<el-option v-for="(item,index) in deliveryOption" :key="index" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-input v-model="screenForm.logistic_code" clearable>
									<template slot="prefix">{{$t('seller.screen.waybillNo')}}</template>
								</el-input>
							</el-form-item>
						</template>
						<el-form-item>
							<el-button @click="handleSearchList" round>{{$t('seller.actions.search')}}</el-button>
							<span class="clear-btn" @click="handleSearchClear"><i class="el-icon-brush"></i>{{$t('seller.actions.reset')}}</span>
						</el-form-item>
					</el-form>
				</div>
			
				<div class="uu-container">
					<template v-if="orderList && orderList.length>0">
					<el-checkbox-group v-model="checkedOrder" @change="handleCheckedOrderChange">
						<div class="order-item" v-for="order,index in orderList" :key="index">
							<table cellpadding="0" cellspacing="0" width="100%">
								<tr>
									<td colspan="3" class="bg-grey">
										<div class="table-head">
											<div class="number">
												<el-checkbox v-if="activeTabs==='accepted'" :label="order.order_id">{{ }}</el-checkbox>
												<span>{{$t('seller.order.number')}}{{order.order_sn}}</span>
											</div>
											<div class="datetimes">
												<span v-if="activeTabs == 'accepted'">{{$t('seller.order.payment')}}{{getPayHour(order.pay_time)}}{{$t('seller.unit.hour')}}</span>
												<span v-else>{{$t('seller.order.shippingTime')}}：{{order.ship_time?order.ship_time:'-'}}</span>
												<span>{{$t('seller.order.payTime')}}：{{order.pay_time?order.pay_time:'-'}}</span>
											</div>
										</div>
									</td>
								</tr>
								<tr v-for="goods,index2 in order.order_goods" :key="index2">
									<td width="50%">
										<div class="table-goods">
											<img class="pic" :src="goods.goods_image" @error="imageLoadError" alt="">
											<div class="goods-main">
												<div class="name"><a :href="$itemBasePath+'/m/item/good/'+goods.goods_id" target="_blank">{{goods.goods_name}}</a></div>
											</div>
										</div>
									</td>
									<td width="10%">￥{{goods.price}} * {{goods.quantity}}</td>
									<td width="40%">
										<div class="table-text">
											<span class="text-grey">{{$t('seller.order.shippingTime')}}</span>
											<span>48{{$t('seller.unit.hour')+$t('seller.order.shipping')}}</span>
										</div>
									</td>
								</tr>
								<tr>
									<td colspan="3">
										<div class="table-foot">
											<div class="address">
												<i class="el-icon-location-outline"></i>
												<div class="address-box">
													<p>
														<span v-if="visibleAddressIds.includes(order.order_id)">{{order.receive_address.region_name}}{{order.receive_address.address}}，{{order.receive_address.zipcode?order.receive_address.zipcode:'000000'}}，{{order.receive_address.consignee}}，{{order.receive_address.phone_mob}}</span>
														<span v-else>{{order.receive_address.region_name}}{{onAddressText(order.receive_address.address)}}，{{onAddressName(order.receive_address.consignee)}}，{{onAddressMobild(order.receive_address.phone_mob)}}</span>
														<i class="uu-icon-view" :class="visibleAddressIds.includes(order.order_id)?'active':''" @click="handleVisibleAddress(order.order_id)"></i>
														<i class="el-icon-edit" @click="handleEditAddressShow(order)"></i>
													</p>
													<p class="text-grey" v-if="activeTabs==='shipped'">
														<span v-if="order.shipping_info.length>0">{{order.shipping_info[0].delivery_name?order.shipping_info[0].delivery_name:$t('seller.order.shopDelivery')}}：{{order.shipping_info[0].logistic_code}}</span>
														<span v-else>{{$t('seller.order.unShippingType')}}</span>
													</p>
												</div>
											</div>
											<div class="action">
												<ul>
													<li v-if="activeTabs == 'accepted'">
														<router-link :to="{ name: 'consignOrder', params: { type: 'list', id: order.order_id }}">
															<el-button type="primary" size="small" round>{{$t('seller.actions.orderShipping')}}</el-button>
														</router-link>
													</li>
													<template v-else>
														<li v-if="order.shipping_info.length>0"><el-popover
															placement="top-end"
															width="240"
															trigger="click">
															<div class="delivery-item" v-for="(ship,index2) in order.shipping_info" :key="index2">
																<span>{{ship.delivery_name?ship.delivery_name:$t('seller.order.shopDelivery')}}: {{ship.logistic_code}}</span>
																<a href="javascript:void(0);" @click="handleEditDelivery(ship)">{{$t('seller.actions.modify')}}</a>
															</div>
															<el-button slot="reference" size="small" round>{{$t('seller.actions.modifyOrderSn')}}</el-button>
														</el-popover></li>
														<li>
															<router-link :to="{ name: 'consignDetail', params: { id: order.order_id }}">
																<el-button type="primary" size="small" round>{{$t('seller.actions.logisticsDetails')}}</el-button>
															</router-link>
														</li>
													</template>
												</ul>
											</div>
										</div>
									</td>
								</tr>
							</table>
						</div>
						<div class="uu-footert">
							<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
								:current-page.sync="pagecurr" :page-size="pagesize" layout="total, prev, pager, next"
								:prev-text="$t('seller.pagination.prevText')" :next-text="$t('seller.pagination.nextText')" :total="listTotal">
							</el-pagination>
						</div>
					</el-checkbox-group>
					</template>
					<el-empty :description="$t('seller.empty.nodata')" v-else></el-empty>
				</div>
			</div>
			
			<el-dialog
				:title="$t('seller.dialogTitle.modifyAddress')"
				width="876px"
				class="dialog-address"
				:visible.sync="dialogVisibleAddress"
				:before-close="handleEditAddressHide">
				<el-scrollbar>
					<div class="dialog-address-main">
						<div class="item">
							<div class="item-label">{{$t('seller.order.dialogAddress1')}}</div>
							<div class="item-block">{{orderInfo.order_sn}}</div>
						</div>
						<div class="item">
							<div class="item-label">{{$t('seller.order.dialogAddress2')}}</div>
							<div class="item-block">
								<div class="address-line">
									<p><span class="line-label">{{$t('seller.order.consignee')}}</span><span>{{orderInfo.receive_address?orderInfo.receive_address.consignee:''}}</span></p>
									<p><span class="line-label">{{$t('seller.order.receivingTel')}}</span><span>{{orderInfo.receive_address?orderInfo.receive_address.phone_mob:''}}</span></p>
									<p><span class="line-label">{{$t('seller.order.receivingAddress')}}</span><span>{{orderInfo.receive_address?orderInfo.receive_address.region_name:''}}{{orderInfo.receive_address?orderInfo.receive_address.address:''}}</span></p>
								</div>
							</div>
						</div>
						<div class="item">
							<div class="item-label">{{$t('seller.order.dialogAddress3')}}</div>
							<div class="item-block">
							</div>
						</div>
						<div class="item">
							<el-form label-position="top" size="small" style="width: 100%;">
								<el-row :gutter="20">
									<el-col :span="12">
										<el-form-item>
											<div class="item-title" slot="label">
												<span>{{$t('seller.formItem.consignee')}}</span>
												<a href="javascript:void(0);" @click="handleEditAddressAction('consignee')">{{addressDisabled.consignee?$t('seller.actions.modify'):$t('seller.actions.backModify')}}</a>
											</div>
											<el-input v-model="addressForm.consignee" :disabled="addressDisabled.consignee"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item>
											<div class="item-title" slot="label">
												<span>{{$t('seller.formItem.receivingTel')}}</span>
												<a href="javascript:void(0);" @click="handleEditAddressAction('phone_mob')">{{addressDisabled.phone_mob?$t('seller.actions.modify'):$t('seller.actions.backModify')}}</a>
											</div>
											<el-input v-model="addressForm.phone_mob" :maxlength="16" :disabled="addressDisabled.phone_mob"></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<el-form-item>
									<div class="item-title" slot="label">
										<span>{{$t('seller.formItem.landline')}}</span>
										<a href="javascript:void(0);" @click="handleEditAddressAction('phone_tel')">{{addressDisabled.phone_tel?$t('seller.actions.modify'):$t('seller.actions.backModify')}}</a>
									</div>
									<el-row>
										<el-col :span="12"><el-input v-model="addressForm.phone_tel" :maxlength="16" :disabled="addressDisabled.phone_tel" :placeholder="$t('seller.placeholder.landline')"></el-input></el-col>
									</el-row>
								</el-form-item>
								<div class="item">
									<div class="item-title">
										<span>{{$t('seller.formItem.receivingAddress')}}</span>
										<a href="javascript:void(0);" @click="handleEditAddressAction('region')">{{addressDisabled.region?$t('seller.actions.modify'):$t('seller.actions.backModify')}}</a>
									</div>
								</div>
								<div style="padding-left: 20px;">
									<el-row :gutter="20">
										<el-col :span="12" v-for="(item,index) in regionList" :key="index">
											<el-form-item :label="onRegionName(index)">
												<el-select v-model="regionIds[index]" @change="handleAddressChange($event,index)" :disabled="addressDisabled.region" clearable>
													<el-option v-for="address in item" :key="address.region_id" :label="address.region_name"
														:value="address.region_id"></el-option>
												</el-select>
											</el-form-item>
										</el-col>
									</el-row>
									<el-form-item :label="$t('seller.formItem.address')">
										<el-input type="textarea" :rows="4" v-model="addressForm.address" :disabled="addressDisabled.region"></el-input>
									</el-form-item>
								</div>
							</el-form>
						</div>
					</div>
				</el-scrollbar>
				<div slot="footer">
					<el-button @click="handleEditAddressHide">{{$t('seller.actions.cancel')}}</el-button>
					<el-button type="primary" @click="handleEditAddressConfirm" :disabled="confirmDissbled">{{$t('seller.actions.confirm')}}</el-button>
				</div>
			</el-dialog>
			<el-dialog
				:title="$t('seller.dialogTitle.modifyWaybillNo')"
				:visible.sync="dialogVisibleDelivery"
				width="460px"
				:before-close="handleCloseDelivery">
				<el-form label-width="80px">
					<el-form-item :label="$t('seller.formItem.logisticsNo')">
						<el-input v-model="deliveryData.logistic_code" :placeholder="$t('seller.placeholder.logisticsNo')"></el-input>
					</el-form-item>
				</el-form>
				
				<div slot="footer" class="dialog-footer">
					<el-button @click="handleCloseDelivery">{{$t('seller.actions.cancel')}}</el-button>
					<el-button type="primary" @click="handleConfirmDelivery">{{$t('seller.actions.confirm')}}</el-button>
				</div>
			</el-dialog>
			<div class="uu-bottom" v-if="activeTabs==='accepted' && orderList.length>0">
				<div class="bottom-bar">
					<el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">{{$t('seller.checkbox.checkAll')}}</el-checkbox>
					<span class="number">{{$t('seller.screen.selected')}}<em>{{checkedOrder.length}}</em></span>
				</div>
				<div class="bottom-box">
					<el-button type="primary" @click="handleBarchConsign(2)" round>{{$t('seller.actions.batchShipping')}}</el-button>
					<el-button @click="handleBarchConsign(1)" round>{{$t('seller.actions.unlogisticsShipping')}}</el-button>
				</div>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { fetchRegion } from '@/api/user';
	import { getOrderList, updateAddress, modifyWaybillNo } from '@/api/seller/order';
	const defaultScreen = {
		type: '',
		shipment_alert: '',
		consignee: '',
		phone_mob: '',
		order_sn: '',
		region_id: '',
		source: '',
		goods_ids: '',
		pay_time_from: '',
		pay_time_to: '',
		delivery_type: '',
		logistic_code: '',
	}
	const defaultAddress = {
		address: '',
		consignee: '',
		phone_mob: '',
		phone_tel: '',
		region_id: 0,
		region_id0: 0,
		region_id1: 0,
		region_id2: 0,
		region_id3: 0,
		region_name: '',
		zipcode: '',
	}
	const defaultDisabled = { consignee: true, phone_mob: true, phone_tel: true, region: true }
	const defaultDelivery = { delivery_dist_id: '', logistic_code: '', order_sn: '' }

	export default {
		data() {
			let _self = this;
			return {
				activeTabs: 'accepted',
				navList: Object.assign([],this.$t('seller.order.consignNav')),
				screenForm: Object.assign({},defaultScreen),
				typeOption: Object.assign([],this.$t('seller.order.consignTypeOption')),
				sourceOption: Object.assign([],this.$t('seller.order.sourceOption')),
				deliveryOption:  Object.assign([],this.$t('seller.order.deliveryOption')),
				containRemark: '',
				makeTime: '',
				screenRegion: [],
				startPickerOptions: {
					disabledDate: (time) => {
						let date = new Date();
						let endTime = new Date(date.getFullYear(),date.getMonth(),date.getDate(),23,59,59);
						return time.getTime() > endTime.getTime();
					}
				},
				orderList: [],
				pagesize: 10,
				pagecurr: 1,
				listTotal: 0,
				addressList: [],
				addressProps: {
					checkStrictly: true,
					value: 'region_id',
					label: 'region_name',
					lazy: true,
					lazyLoad(node, resolve) {
						const level = node.level;
						_self.getAddressNode(node, function(res) {
							if (res.code == 200) {
								const nodes = res.data.list;
								nodes.forEach((item) => {
									item.leaf = level >= 2;
								});
								resolve(nodes);
							}
						})
					}
				},
				checkedOrder: [],
				checkAll: false,
				isIndeterminate: false,
				visibleAddressIds: [],
				dialogVisibleAddress: false,
				orderInfo: {},
				addressForm: Object.assign({},defaultAddress),
				addressDisabled: Object.assign({},defaultDisabled),
				confirmDissbled: true,
				regionList: [],
				regionIds: [],
				dialogVisibleDelivery: false,
				deliveryData: Object.assign({},defaultDelivery),
			}
		},
		created() {
			this.getOrderlist();
			this.getRegionList();
		},
		methods: {
			imageLoadError(e) {
				e.target.src = this.$imageErrorGoods;
				e.target.style.objectFit = 'fill';
			},
			getOrderlist() {
				let loading = this.$loading();
				let param = {
					...this.screenForm,
					contain_remark: this.containRemark,
					status: this.activeTabs,
					page_index: (this.pagecurr - 1) * this.pagesize,
					page_size: this.pagesize
				};
				getOrderList(param).then(response => {
					loading.close();
					this.orderList = response.data.list;
					this.listTotal = parseInt(response.data.total);
				}).catch(() => {
					loading.close();
				})
			},
			getRegionList(pid = 45056, index = -1) {
				fetchRegion({parent_id: pid}).then(response => {
					this.regionList.splice(index);
					if (response.data.list && response.data.list.length > 0) {
						this.regionList.push(response.data.list);
					}
				})
			},
			getAddressNode(node, callback) {
				const id = node.value?node.value:45056;
				let param = {
					parent_id: id,
					app_key: this.$store.getters.appKey
				};
				fetchRegion(param).then(response => {
					callback(response);
				}).catch(error => {
					callback(error);
				})
			},
			getPayHour(datetime){
				if(datetime){
					var date = new Date();
					var curTime = new Date(datetime);
					var hour = (date.getTime()-curTime.getTime())/3600000;
					return parseInt(hour);
				}else{
					return '-';
				}
			},
			handleTabsClick(tab, event) {
				this.orderList = [];
				this.listTotal = 0;
				this.containRemark = '';
				this.handleSearchClear();
			},
			handleSearchList(val) {
				let start_time = parseInt(this.makeTime[0]) / 1000;
				let end_time = parseInt(this.makeTime[1]) / 1000;
				let region_id = this.screenRegion[this.screenRegion.length - 1];
				this.screenForm.pay_time_from = start_time > 0 ? start_time : '';
				this.screenForm.pay_time_to = end_time > 0 ? end_time : '';
				this.screenForm.region_id = region_id > 0 ? region_id : '';
				this.pagecurr = 1;
				this.getOrderlist();
			},
			handleWarn(num){
				if(this.screenForm.shipment_alert == num){
					this.screenForm.shipment_alert = '';
				}else {
					this.screenForm.shipment_alert = num;
				}
				this.handleSearchList();
			},
			handleSearchClear() {
				this.screenForm = Object.assign({},defaultScreen)
				this.makeTime = '';
				this.screenRegion = [];
				this.pagecurr = 1;
				this.getOrderlist();
			},
			handleSizeChange(val) {
				this.pagesize = val;
			},
			handleCurrentChange(val) {
				this.pagecurr = val;
				this.handleSearchList();
			},
			handleCheckAllChange(val) {
				let ids = [];
				if(val){
					this.orderList.forEach(item=>{
						ids.push(item.order_id);
					})
				}
				this.checkedOrder = ids;
				console.log();
				this.isIndeterminate = false;
			},
			handleCheckedOrderChange(value){
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.orderList.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.orderList.length;
			},
			onAddressText(val){
				return "*****************";
			},
			onAddressName(val){
				return val.substr(0,1)+"**";
			},
			onAddressMobild(tel){
				return "***********";
			},
			handleVisibleAddress(index){
				if(this.visibleAddressIds.includes(index)){
					this.visibleAddressIds.splice(this.visibleAddressIds.indexOf(index),1);
				}else{
					this.visibleAddressIds.push(index);
				}
			},
			handleEditAddressShow(data){
				let that = this;
				let promiseArr = [];
				let regionIds = [];
				that.orderInfo = data;
				that.addressForm = JSON.parse(JSON.stringify(data.receive_address));
				that.regionList.splice(1);
				for(let i=0;i<4;i++){
					if(that.addressForm['region_id' + i] > 0){
						regionIds.push(that.addressForm['region_id' + i]);
						promiseArr.push(that.initRegionList(that.addressForm['region_id' + i]));
					}
				}
				Promise.all(promiseArr).then(object=>{
					object.forEach(item=>{
						if(item.length>0){
							that.regionList.push(item);
						}
					})
				})
				that.regionIds = regionIds;
				that.dialogVisibleAddress = true;
			},
			initRegionList(pid=45056){
				return new Promise((resolve,reject)=>{
					fetchRegion({parent_id: pid}).then(response => {
						resolve(response.data.list)
					}).catch(error => {
						reject(error)
					})
				})
			},
			handleEditAddressAction(type){
				let that = this;
				if(!this.addressDisabled[type]){
					let data = JSON.parse(JSON.stringify(this.orderInfo.receive_address));
					let promiseArr = [];
					let regionIds = [];
					switch(type){
						case 'consignee':
							this.addressForm.consignee = data.consignee;
						break;
						case 'phone_mob':
							this.addressForm.phone_mob = data.phone_mob;
							break;
						case 'phone_tel':
							this.addressForm.phone_tel = data.phone_tel;
							break;
						case 'region':
							that.regionList.splice(1);
							for(let i=0;i<4;i++){
								if(that.addressForm['region_id' + i] > 0){
									regionIds.push(that.addressForm['region_id' + i]);
									promiseArr.push(that.initRegionList(that.addressForm['region_id' + i]));
								}
							}
							Promise.all(promiseArr).then(object=>{
								object.forEach(item=>{
									if(item.length>0){
										that.regionList.push(item);
									}
								})
							})
							that.regionIds = regionIds;
							this.addressForm.address = data.address;
							break;
					}
				}
				this.addressDisabled[type] = this.addressDisabled[type]?false:true;
			},
			handleEditAddressHide(){
				this.orderInfo = {};
				this.addressForm = {};
				this.addressDisabled = Object.assign({},defaultDisabled)
				this.dialogVisibleAddress = false;
			},
			onRegionName(index){
				const regionJson =  this.$t('seller.order.regionJson')
				return regionJson[index];
			},
			handleAddressChange(val, index){
				if(val){
					this.regionIds.splice(index + 1);
					this.getRegionList(val, index + 1);
				}else {
					this.regionIds.splice(index);
					this.regionList.splice(index+1);
				}
			},
			handleEditAddressConfirm(){
				let that = this;
				if(!that.addressForm.consignee){
					that.$message.error(that.$t('seller.errorMsg.consignee'));
					return false;
				}
				if(!that.addressForm.phone_mob){
					that.$message.error(that.$t('seller.errorMsg.recipientMobile'));
					return false;
				}
				if(that.regionIds){
					that.addressForm.region_id = that.regionIds[that.regionIds.length-1];
					that.addressForm.region_id0 = that.regionIds[0] > 0 ? that.regionIds[0] : 0;
					that.addressForm.region_id1 = that.regionIds[1] > 0 ? that.regionIds[1] : 0;
					that.addressForm.region_id2 = that.regionIds[2] > 0 ? that.regionIds[2] : 0;
					that.addressForm.region_id3 = that.regionIds[3] > 0 ? that.regionIds[3] : 0;
					let regionName = '';
					for(let i = 0;i<that.regionIds.length;i++){
						that.regionList[i].forEach(item=>{
							if(item.region_id == that.regionIds[i]){
								regionName+=item.region_name;
							}
						})
					}
					that.addressForm.region_name = regionName;
				}else {
					that.$message.error(that.$t('seller.errorMsg.region'));
					return false;
				}
				if(!that.addressForm.address){
					that.$message.error(that.$t('seller.errorMsg.address'));
					return false;
				}
				let loading = that.$loading();
				let param = {
					...that.addressForm,
					order_id: that.orderInfo.order_id
				}
				updateAddress(param).then(() => {
					loading.close();
					that.$message.success(that.$t('seller.successMsg.modify'))
					that.handleEditAddressHide();
					that.getOrderlist();
				}).catch(() => {
					loading.close();
				})
			},
			handleBarchConsign(type){
				if(!this.checkedOrder.length>0){
					this.$message.error(this.$t('seller.errorMsg.checkOrder'));
					return false;
				}
				if(type==1){
					this.$router.push({ name: 'consignOrder', params: { type: 'nologis', id: this.checkedOrder.join(',') }})
				}else{
					this.$router.push({ name: 'consignOrder', params: { type: 'logis', id: this.checkedOrder.join(',') }})
				}
			},
			handleEditDelivery(data){
				this.deliveryData = {
					delivery_dist_id: data.delivery_dist_id,
					logistic_code: '',
					order_sn: data.order_sn,
				};
				this.dialogVisibleDelivery = true;
			},
			handleCloseDelivery(){
				this.deliveryData = Object.assign({},defaultDelivery)
				this.dialogVisibleDelivery = false;
			},
			handleConfirmDelivery(){
				if(!this.deliveryData.logistic_code){
					this.$message.error(this.$t('seller.errorMsg.logisticsNo'));
					return false;
				}
				modifyWaybillNo(this.deliveryData).then(() => {
					this.$message.success(this.$t('seller.successMsg.modify'));
					this.deliveryData = Object.assign({},defaultDelivery)
					this.getOrderlist();
					this.dialogVisibleDelivery = false;
				})
			}
		},
		watch: {
			'addressForm': {
				handler: function(val, oldVal){
					if(val.order_id == oldVal.order_id){
						this.confirmDissbled = false;
					}else {
						this.confirmDissbled = true;
					}
				},
				deep: true,
			}
		}
	}
</script>

<style lang="scss" scoped>
	.uu-order {
		padding-bottom: 64px;
		::v-deep .el-tabs__header {
			margin-bottom: 0;
			.el-tabs__nav-wrap {
				padding: 0 24px;
			}

			.el-tabs__nav-wrap::after {
				height: 1px;
			}
		}

		.uu-message {
			padding: 14px 22px;
			.message-notice {
				padding: 12px;
				border-radius: 9px;
				line-height: 18px;
				background-color: $--seller-notice-background;
				.el-icon-warning {
					color: $--seller-primary;
					float: left;
					font-size: 18px;
				}
				.message-notice-main {
					width: 100%;
					color: #666666;
					font-size: 12px;
					padding-left: 24px;
					padding-right: 15px;
					box-sizing: border-box;
					a {
						color: $--seller-primary;
					}
				}
			}
		}

		.uu-order-main {
			padding: 18px 24px;
			.uu-order-head {
				margin-bottom: 18px;
				li {
					line-height: 32px;
					display: inline-block;
					margin-right: 56px;
					::v-deep .el-radio-group {
						.el-radio-button {
							margin-right: 10px;
							.el-radio-button__inner {
								border-radius: 4px;
								color: #333333;
								background-color: #f7f8fa;
								border: 1px solid #DCDFE6;
							}
							.el-radio-button__orig-radio:checked+.el-radio-button__inner {
								color: $--seller-button-primary;
								border-color: $--seller-button-primary;
								background-color: #f7f8fa;
								box-shadow: none;
							}
						}
					}
					.li-black {
						display: inline-block;
						::v-deep .el-button {
							border-radius: 4px;
							color: #333333;
							background-color: #f7f8fa;
							border: 1px solid #DCDFE6;
						}
						.el-button.active {
							color: $--seller-button-primary;
							border-color: $--seller-button-primary;
							background-color: #f7f8fa;
						}
					}
				}
			}
			.uu-screen {
				border-bottom: 1px solid #e6e8ed;
				margin-bottom: 16px;

				.el-form {
					margin: 0 -9px;

					.el-form-item {
						width: calc(25% - 18px);
						max-width: 240px;
						margin: 0px 9px 16px;

						::v-deep .el-form-item__content {
							width: 100%;

							.el-input__inner {
								padding-left: 72px;
								background-color: $--seller-filter-input-background;
							}
							.screen-input {
								.el-input__inner {
									padding-left: 84px;
								}
							}

							.el-input__inner:focus,
							.el-input__inner:hover {
								background-color: #FFFFFF;
								border-color: $--seller-primary;
							}

							.el-select {
								width: 100%;
							}

							.el-date-editor--datetimerange.el-input__inner {
								width: 100%;
								padding-left: 52px;
							}

							.item {
								position: relative;

								.item-label {
									top: 0;
									left: 10px;
									z-index: 9;
									height: 100%;
									color: #C0C4CC;
									text-align: center;
									position: absolute;
								}
							}
						}

						.clear-btn {
							cursor: pointer;
							color: #666666;
							margin-left: 12px;

							i {
								margin-right: 4px;
							}
						}

						.clear-btn:hover {
							color: $--seller-primary;
						}
					}

					.form-item-time {
						width: calc(50% - 18px);
						max-width: 498px;
					}
				}
			}

			.uu-container {
				.order-item {
					border-radius: 6px;
					margin-bottom: 10px;
					border: 1px solid #E5E5E5;
					table {
						font-size: 14px;
					}
					td {
						padding: 12px;
						line-height: 20px;
						border-bottom: 1px solid #E5E5E5;
					}
					td.bg-grey {
						background-color: $--seller-thead-background-1;
					}
					tr:last-child {
						td {
							padding: 8px 12px;
							border-bottom: none;
						}
					}
					.table-head {
						display: flex;
						justify-content: space-between;
						.datetimes {
							span {
								margin-right: 20px;
							}
						}
					}
					.table-goods {
						display: flex;
						.pic {
							width: 40px;
							height: 40px;
							border-radius: 4px;
							object-fit: cover;
							margin-right: 16px;
						}
						.goods-main {
							flex: 1;
							width: 0;
							display: flex;
							align-items: center;
							flex-flow: wrap;
							div {
								flex-shrink: 0;
								width: 100%;
							}
							a {
								color: $--seller-primary;
							}
						}
					}
					.table-text {
						span {
							margin-right: 10px;
						}
						.text-grey {
							color: #999999;
						}
					}
					.table-foot {
						justify-content: space-between;
						display: flex;
						align-items: center;
						.address {
							display: flex;
							// align-items: center;
							.el-icon-location-outline {
								font-size: 16px;
								line-height: 20px;
								margin-right: 6px;
							}
							.address-box {
								span {
									display: inline-block;
									vertical-align: top;
								}
								i {
									width: 20px;
									height: 20px;
									line-height: 20px;
									text-align: center;
									margin-left: 10px;
									color: #888888;
									cursor: pointer;
									display: inline-block;
									vertical-align: top;
								}
								.uu-icon-view {
									display: inline-block;
									background-image: url('~@/assets/images/seller/order-see.png');
									background-size: auto 100%;
									background-repeat: no-repeat;
									background-position: left;
								}
								.uu-icon-view.active {
									background-position: right;
								}
								.text-grey {
									span {
										color: #888888;
										font-size: 12px;
										margin-right: 20px;
									}
								}
							}
						}
						.action {
							li {
								display: inline-block;
								margin-left: 10px;
							}
						}
					}
				}
			}

			.uu-footert {
				padding: 5px 0 15px;
				text-align: center;
			}
		}
		.dialog-address {
			::v-deep .el-scrollbar {
				height: 500px!important;
			}
			.dialog-address-main {
				padding: 0 40px;
				overflow: hidden;
				.item {
					display: flex;
					line-height: 32px;
					margin-bottom: 5px;
					font-size: 14px;
					.item-label {
						width: 100px;
						padding-right: 10px;
					}
					.item-block {
						flex: 1;
						width: 0;
						display: block;
					}
					.item-title {
						span {
							margin-right: 20px;
						}
						a {
							color: $--seller-primary;
							text-decoration: underline;
						}
					}
				}
				.address-line {
					padding: 4px 0;
					line-height: 32px;
					.line-label {
						min-width: 68px;
						display: inline-block;
						color: #999999;
					}
				}
				::v-deep .el-form {
					.el-form-item {
						margin-bottom: 10px;
					}
				}
				::v-deep .el-select {
					width: 100%;
				}
			}
		}
		.uu-bottom {
			height: 64px;
			padding: 0 40px;
			display: flex;
			align-items: center;
			position: absolute;
			bottom: 0;
			width: 100%;
			z-index: 9;
			box-sizing: border-box;
			box-shadow: 0 -1px 3px 0 rgba(0,0,0,.06);
			background-color: #FFFFFF;
			.bottom-bar {
				.number {
					font-size: 14px;
					padding-left: 9px;
					margin-left: 9px;
					border-left: 1px solid #e6e8ed;
					em {
						color: #f78a4a;
						margin-left: 4px;
					}
				}
			}
			.bottom-box {
				flex: 1;
				width: 0;
				display: block;
				text-align: center;
			}
		}
	}
	.delivery-item {
		line-height: 24px;
		span {
			margin-right: 10px;
		}
		a {
			color: $--seller-primary;
		}
	}
</style>
